import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private timer: any;
  private countdownEndSource = new Subject<void>();
  public onTimerComplete = this.countdownEndSource.asObservable();

  constructor() {}

  public setTimer(duration: number) {
    const startTime = parseInt(localStorage.getItem('timerStartTime') || '0');
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - startTime;
    const remainingTime = duration - elapsedTime;
  
    if (remainingTime <= 0) {
      localStorage.setItem('timerStartTime', currentTime.toString());
      this.countdownEndSource.next();
    } else {
      this.timer = setTimeout(() => {
        localStorage.setItem('timerStartTime', currentTime.toString());
        this.countdownEndSource.next();
      }, remainingTime);
    }
  }

  public clearTimer() {
    clearTimeout(this.timer);
    localStorage.removeItem('timerStartTime');
  }

  public resumeTimer() {
    const startTime = parseInt(localStorage.getItem('timerStartTime') || '0');
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - startTime;
    const remainingTime = 5 * 60 * 1000 - elapsedTime;

    if (remainingTime <= 0) {
      this.countdownEndSource.next();
    } else {
      this.timer = setTimeout(() => {
        localStorage.setItem('timerStartTime', currentTime.toString());
        this.countdownEndSource.next();
      }, remainingTime);
    }
  }
}
