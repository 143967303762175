import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'desarrollador',
    loadChildren: () => import('./desarrollador/desarrollador.module').then( m => m.DesarrolladorPageModule)
  },
  {
    path: 'ayudas',
    loadChildren: () => import('./ayudas/ayudas.module').then( m => m.AyudasPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'calzador',
    loadChildren: () => import('./AyTec/calzador/calzador.module').then( m => m.CalzadorPageModule)
  },
  {
    path: 'deslizador',
    loadChildren: () => import('./AyTec/deslizador/deslizador.module').then( m => m.DeslizadorPageModule)
  },
  {
    path: 'guantes',
    loadChildren: () => import('./AyTec/guantes/guantes.module').then( m => m.GuantesPageModule)
  },
  {
    path: 'enrollador',
    loadChildren: () => import('./AyTec/enrollador/enrollador.module').then( m => m.EnrolladorPageModule)
  },
  {
    path: 'comofunciona',
    loadChildren: () => import('./comofunciona/comofunciona.module').then( m => m.ComofuncionaPageModule)
  },
  {
    path: 'pasouno',
    loadChildren: () => import('./pasouno/pasouno.module').then( m => m.PasounoPageModule)
  },
  {
    path: 'prelongpunt',
    loadChildren: () => import('./prevencion/prelongpunt/prelongpunt.module').then( m => m.PrelongpuntPageModule)
  },
  {
    path: 'especiales',
    loadChildren: () => import('./personalizacion/especiales/especiales.module').then( m => m.EspecialesPageModule)
  },
  {
    path: 'quemaduras',
    loadChildren: () => import('./personalizacion/quemaduras/quemaduras.module').then( m => m.QuemadurasPageModule)
  },
  {
    path: 'ejercicio',
    loadChildren: () => import('./personalizacion/ejercicio/ejercicio.module').then( m => m.EjercicioPageModule)
  },
  {
    path: 'medicamentoso',
    loadChildren: () => import('./personalizacion/medicamentoso/medicamentoso.module').then( m => m.MedicamentosoPageModule)
  },
  {
    path: 'inmovilidaad',
    loadChildren: () => import('./personalizacion/inmovilidaad/inmovilidaad.module').then( m => m.InmovilidaadPageModule)
  },
  {
    path: 'formacion',
    loadChildren: () => import('./formacion/formacion.module').then( m => m.FormacionPageModule)
  },
  {
    path: 'glosario',
    loadChildren: () => import('./glosario/glosario.module').then( m => m.GlosarioPageModule)
  },
  {
    path: 'obesidad',
    loadChildren: () => import('./personalizacion/obesidad/obesidad.module').then( m => m.ObesidadPageModule)
  },
  {
    path: 'icrenal',
    loadChildren: () => import('./personalizacion/icrenal/icrenal.module').then( m => m.IcrenalPageModule)
  },
  {
    path: 'lipedema',
    loadChildren: () => import('./personalizacion/lipedema/lipedema.module').then( m => m.LipedemaPageModule)
  },
  {
    path: 'lipedema1',
    loadChildren: () => import('./Lipedema/lipedema1/lipedema1.module').then( m => m.Lipedema1PageModule)
  },
  {
    path: 'lipedema2',
    loadChildren: () => import('./Lipedema/lipedema2/lipedema2.module').then( m => m.Lipedema2PageModule)
  },
  {
    path: 'lipedema3',
    loadChildren: () => import('./Lipedema/lipedema3/lipedema3.module').then( m => m.Lipedema3PageModule)
  },
  {
    path: 'lipolinfedema',
    loadChildren: () => import('./Lipedema/lipolinfedema/lipolinfedema.module').then( m => m.LipolinfedemaPageModule)
  },
  {
    path: 'paso2',
    loadChildren: () => import('./personalizacion/IVC/paso2/paso2.module').then( m => m.Paso2PageModule)
  },
  {
    path: 'transtorno',
    loadChildren: () => import('./personalizacion/IVC/transtorno/transtorno.module').then( m => m.TranstornoPageModule)
  },
  {
    path: 'arteriopatia',
    loadChildren: () => import('./complicaciones/arteriopatia/arteriopatia.module').then( m => m.ArteriopatiaPageModule)
  },
  {
    path: 'neuropatia',
    loadChildren: () => import('./complicaciones/neuropatia/neuropatia.module').then( m => m.NeuropatiaPageModule)
  },
  {
    path: 'ivc',
    loadChildren: () => import('./personalizacion/IVC/ivc/ivc.module').then( m => m.IvcPageModule)
  },
  {
    path: 'obesidadivc',
    loadChildren: () => import('./personalizacion/IVC/obesidadivc/obesidadivc.module').then( m => m.ObesidadivcPageModule)
  },
  {
    path: 'sistemicosivc',
    loadChildren: () => import('./personalizacion/IVC/sistemicosivc/sistemicosivc.module').then( m => m.SistemicosivcPageModule)
  },
  {
    path: 'lipedemaivc',
    loadChildren: () => import('./personalizacion/IVC/lipedemaivc/lipedemaivc.module').then( m => m.LipedemaivcPageModule)
  },
  {
    path: 'flebolinfedema',
    loadChildren: () => import('./personalizacion/IVC/flebolinfedema/flebolinfedema.module').then( m => m.FlebolinfedemaPageModule)
  },
  {
    path: 'c3c6',
    loadChildren: () => import('./personalizacion/IVC/c3c6/c3c6.module').then( m => m.C3c6PageModule)
  },
  {
    path: 'c4c6',
    loadChildren: () => import('./personalizacion/IVC/c4c6/c4c6.module').then( m => m.C4c6PageModule)
  },
  {
    path: 'ivcdeformidad',
    loadChildren: () => import('./personalizacion/IVC/ivcdeformidad/ivcdeformidad.module').then( m => m.IvcdeformidadPageModule)
  },
  {
    path: 'prevtvp',
    loadChildren: () => import('./personalizacion/IVC/prevtvp/prevtvp.module').then( m => m.PrevtvpPageModule)
  },
  {
    path: 'prespt',
    loadChildren: () => import('./personalizacion/IVC/prespt/prespt.module').then( m => m.PresptPageModule)
  },
  {
    path: 'linfedema',
    loadChildren: () => import('./personalizacion/linfedema/linfedema/linfedema.module').then( m => m.LinfedemaPageModule)
  },
  {
    path: 'linfedema1',
    loadChildren: () => import('./personalizacion/linfedema/linfedema1/linfedema1.module').then( m => m.Linfedema1PageModule)
  },
  {
    path: 'linfedema2',
    loadChildren: () => import('./personalizacion/linfedema/linfedema2/linfedema2.module').then( m => m.Linfedema2PageModule)
  },
  {
    path: 'linfedema3',
    loadChildren: () => import('./personalizacion/linfedema/linfedema3/linfedema3.module').then( m => m.Linfedema3PageModule)
  },
  {
    path: 'flebolinfedemae23',
    loadChildren: () => import('./personalizacion/linfedema/flebolinfedemae23/flebolinfedemae23.module').then( m => m.Flebolinfedemae23PageModule)
  },
  {
    path: 'medi',
    loadChildren: () => import('./medi/medi.module').then( m => m.MediPageModule)
  },
  {
    path: 'menudivisor',
    loadChildren: () => import('./menudivisor/menudivisor.module').then( m => m.MenudivisorPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./Pacientes/contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'cuidados',
    loadChildren: () => import('./Pacientes/cuidados/cuidados.module').then( m => m.CuidadosPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./Pacientes/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'inicio-pacientes',
    loadChildren: () => import('./Pacientes/inicio-pacientes/inicio-pacientes.module').then( m => m.InicioPacientesPageModule)
  },


  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
