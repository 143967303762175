import { Component } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { TimerService } from './services/time.service.service';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private alertCtrl: AlertController,
    private timerService: TimerService,
    private platform: Platform
  ) {}

  ngOnInit() {
      FirebaseAnalytics.initializeFirebase({
      apiKey: "AIzaSyAlq3j_HZDknVr0QlupsP8GzhmK7mDs5JQ",
      authDomain: "ayudamedias-free.firebaseapp.com",
      projectId: "ayudamedias-free",
      storageBucket: "ayudamedias-free.appspot.com",
      messagingSenderId: "642606680722",
      appId: "1:642606680722:web:e00fed3c53b9f9bf82f44b",
      measurementId: "G-7XTLG2PHNB"
          });
          this.loadAdSenseScript();
          this.startTimer();
  }
  
  private startTimer() {
    const timerDuration = 300000; // 5 minutos en milisegundos
    this.timerService.setTimer(timerDuration);
  
    this.timerService.onTimerComplete.subscribe(() => {
      this.showAlert();
      this.timerService.clearTimer(); // Detener el temporizador para que no se vuelva a activar
    });
  }

  loadAdSenseScript() {
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
  }
  
  private async showAlert() {
    const alert = await this.alertCtrl.create({
      header: '¿Qué te parece la app?',
      message: 'Por favor, ayúdame a mejorar la aplicación con tu opinión.',
      buttons: [
        {
          text: 'Sí, calificar ahora',
          handler: () => {
            // Abre la tienda de aplicaciones para que el usuario pueda calificar la app
            this.launchAppStore();
          }
        },
        {
          text: 'No, gracias soy amante del crepe',
          role: 'cancel',
          handler: () => {
            // El usuario no quiere calificar la app
          }
        }
      ]
    });
    await alert.present();
  }

  private launchAppStore() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.piernasencompresion.Ayudamediasfree';
  }
  
}

