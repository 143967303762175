import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';



@Component({
  selector: 'app-modal-especificaciones',
  templateUrl: './modal-especificaciones.component.html',
  styleUrls: ['./modal-especificaciones.component.scss'],
})
export class ModalEspecificacionesComponent implements OnInit {
  selectedString: string = ''; 
  @Input() opcionesPrevias: string[] = [];
  selectedOptions = {};
  pie1 = ['protección juanete', 'zona confort en quinto varo', 'protector para dedos'];
  terminaciones = ['terminación distal recta', 'terminación proximal recta'];
  modificaciones = ['refuerzo en Y en tobillo', 'extensión en planta del pie', 'modificación en forma de elipse', 'zona funcional en rodilla', 'modificación: elevación en muslo', 'añadido de hilo de plata'];
  rombos = ['rombo en tricotado', 'rombo en redecilla', 'rombo compresivo'];
  almohadillados = ['almohadilla linfológica', 'material de relleno', 'bolsillo' ];
  modificacionesPanty = ['panty top en forma de glúteo', 'maternity'];
  modificacionesCintura = ['cintura con banda de orificios', 'cintura con banda de cintura', 'cintura con borde tricotado', 'cintura en banda adhesiva con silicona'];
  bandas = ['banda de silicona a lo largo del borde oblicuo ', 'banda de silicona en el punto e (encima de rodilla) ', 'banda de silicona en posterior de la costura', 'banda de silicona en la planta del pie'];
  fijaciones = [ 'fijación de silicona estándar', 'fijación de silicona en puntos', 'fijación de silicona para piel delicada', 'fijación de silicona en la cadera', 'fijación elástica'];
  cremalleras = ['cremallera en lateral', 'cremallera en posterior', 'cremallera en lateral y posterior'];

  constructor(private modalController: ModalController) { }


  ngOnInit() {
    this.inicializarSelectedOptions();
    this.marcarOpcionesPrevias();
}

  inicializarSelectedOptions() {
    // Usaremos un método genérico para evitar repetir código
    const inicializarArray = (arr: string[]) => {
        arr.forEach(item => {
            this.selectedOptions[item] = false;
        });
    };

    // Llamaremos al método genérico para cada lista
    inicializarArray(this.pie1);
    inicializarArray(this.terminaciones);
    inicializarArray(this.modificaciones);
    inicializarArray(this.rombos);
    inicializarArray(this.almohadillados);
    inicializarArray(this.modificacionesPanty);
    inicializarArray(this.modificacionesCintura);
    inicializarArray(this.bandas);
    inicializarArray(this.fijaciones);
    inicializarArray(this.cremalleras);
}


  marcarOpcionesPrevias() {
    for (let opcion of this.opcionesPrevias) {
        if (this.selectedOptions.hasOwnProperty(opcion)) {
            this.selectedOptions[opcion] = true;
        }
    }
    console.log('selectedOptions después de intentar marcar:', this.selectedOptions);
}


  getSelectedOptionsAsString(): string {
    let selectedItems = [];
    for (let key in this.selectedOptions) {
      if (this.selectedOptions[key]) { // Si la opción está seleccionada (checkbox activado)
        selectedItems.push(key);
      }
    }
    return selectedItems.join(', '); // Convierte el arreglo en una cadena delimitada por comas
  }
  
  enviarespecificaciones() {
    const selectedString = this.getSelectedOptionsAsString();
    this.modalController.dismiss(selectedString);
  }
  
}
