import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatListModule} from '@angular/material/list';
import { TimerService } from './services/time.service.service';
import { File } from '@ionic-native/file/ngx/index';
import { FileOpener } from '@ionic-native/file-opener/ngx/index';
import { OpcionesModalComponent } from './opciones-modal-component/opciones-modal-component.component';
import { ModalEspecificacionesComponent } from 'src/app/modal-especificaciones/modal-especificaciones.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent, OpcionesModalComponent, ModalEspecificacionesComponent],
  imports: [ FormsModule, IonicModule, MatListModule, BrowserModule, IonicModule.forRoot(), AppRoutingModule, BrowserAnimationsModule,SuperTabsModule.forRoot()],
  providers: [
        InAppBrowser, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        File,
        FileOpener,
        TimerService,
      ],
  bootstrap: [AppComponent],
})
export class AppModule {}
