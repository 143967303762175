import { Component, OnInit, Inject } from '@angular/core';
import { File } from '@ionic-native/file/ngx/index';
import { FileOpener } from '@ionic-native/file-opener/ngx/index';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-opciones-modal-component',
  templateUrl: './opciones-modal-component.component.html',
  styleUrls: ['./opciones-modal-component.component.scss'],
})
export class OpcionesModalComponent implements OnInit {
  savedBlob: Blob;

  constructor(
    private file: File, 
    private fileOpener: FileOpener,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.savedBlob = data.pdfData;
  }

  ngOnInit() {}

  openPdf() {
    const fileURL = URL.createObjectURL(this.savedBlob);
    window.open(fileURL, '_blank');
  }

  downloadPdf() {
    const link = document.createElement('a');
    const fileURL = URL.createObjectURL(this.savedBlob);
    link.href = fileURL;
    link.download = 'Recomendación de medias - AyudaMedias.pdf';
    link.click();
  }
}
